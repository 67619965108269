<template>
  <div class="Pay QR">
    <p class="path">Frequently Asked Questions > TayoCash Services > Pay QR</p>
    <h3 id="Pay QR">Pay QR</h3>

    <h4 id="pqr1">What is Pay QR?</h4>
    <div class="paragraph">
      <p>
        Pay QR service is a contactless and cashless form of transaction that allows you to pay for our Merchants’ goods and/or services by scanning their assigned QR code using the TayoCash app.
      </p>
    </div>

    <h4 id="pqr2">How do I use the Pay QR PH feature?</h4>
      <div class="paragraph">
        <p>You can use the Pay QR feature at any accredited TayoCash Merchant’s physical or online store.
        </p>
        <ol id="pqr2li">
          <li>Open the app and press the Scan QR Icon found at the top right portion of your screen.</li>
          <li>Place the Merchant’s QR PH code inside the box.</li>
          <li>Enter the amount you need to pay and confirm.</li>
          <li>A transaction receipt will appear in your TayoCash app for the confirmed Pay QR PH transaction. Check your new wallet balance.</li>
          <li>You will receive an SMS confirmation/app notification from TayoCash confirming the transaction.</li>
        </ol>
      </div>

    <h4 id="pqr3">My Pay QR PH transaction is not going through. What should I do?</h4>
    <div class="paragraph">
      <p>
        If you are having trouble scanning a Pay QR PH code, try the following:
        <ul>
          <li>Ensure that your camera lens is clear and that the whole QR PH code is inside the frame of your phone's camera/scanner.</li>

          <li>Check if you have a stable mobile internet connection.</li>

          <li>Check if your TayoCash app software is updated.</li>

          <li>You may also try restarting the app and your phone.</li>

          <li>If none of the above worked, you may <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the <a class="link" href="/helpcenter">Help Center</a> in the TayoCash app or website, or email TayoCash Customer
            Care Group at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>.</li>
        </ul>
      </p>
    </div>

    <h4 id="pqr4">I just made a Pay QR PH transaction, the TayoCash Merchant did not receive a confirmation of my QR payment. What should I do?</h4>
    <div class="paragraph">
      <p>
        Please check your TayoCash Wallet:
        <ul>
          <li>If it has been deducted for the transaction, there might just be a short delay in the SMS confirmation. Please check your mobile network connection. After a few minutes, you may ask the TayoCash Merchant to contact us so we can help.</li>

          <li>If your TayoCash wallet was not deducted, you may try processing the payment again.</li>
        </ul>
      </p>
    </div>

    <h4 id="pqr5">I have put in an amount more than I intended to pay on my Pay QR PH transaction. What should I do?</h4>
    <div class="paragraph">
      <p>
        If you have put in an amount more than what is required, you can ask the TayoCash Merchant for settlement. If the Merchant refuses, you may file a <a class="link" href="https://assets.prod.tayocash.com/forms/transaction_dispute_form.pdf" download target="_blank">Tayocash Dispute Form</a> via the <a class="link" href="/helpcenter">Help Center</a>.
      </p>
      <p>
        Click <a class="link" href="/faq/disputes">here</a> to learn how to file a dispute.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'payqr'
}
</script>

<style scoped lang='scss'></style>
